/* ++++++++++++++++++++++++++++++++++++++++++++
//
// ページがロードされた時
//
++++++++++++++++++++++++++++++++++++++++++++ */

(function($) {
  /* html に デバイスに応じた クラスを付与
    -------------------------------------------- */
  if (gns.browser) gns.html.addClass(gns.browser);
  if (gns.deviceOs) gns.html.addClass(gns.deviceOs);
  if (gns.browser && gns.browserVer) gns.html.addClass(gns.browser + gns.browserVer);
  if (gns.deviceType.tab) gns.html.addClass('tab_device');
  if (gns.deviceType.sp) gns.html.addClass('sp_device');
  if (gns.googlebot) gns.html.addClass('googlebot');

  $(function() {
    gns.body = $('body');
    gns.htmlBody = $('html, body');

    /* 余計な空タグを削除
    -------------------------------------------- */
    $('p:empty').remove();

    /* DOM を代入
    -------------------------------------------- */
    gns.header = $("#site_header");

    /* ターゲットにスクロール
    // 個別に無視する場合には、aタグに data-anchorlink="off" を追加する。
    ------------------------------------------- */
    let anchorLink = $('a[href*="#"]');

    anchorLink.on('click', function() {
      if($(this).hasClass('fancybox')) {
        return false;
      }
      let dataAnchorLink = $(this).data('anchorlink');
      if(dataAnchorLink === 'off') {
        return false;
      }
      let dataOffset = parseInt($(this).data('offset'));
      let targetOffset = 0;
      if(location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
        let target = $(this.hash);
        if(target.length) {
          let targetOffset = target.offset().top;
          if(dataOffset) {
            targetOffset += dataOffset;
          }
          if(gns.anchorOffsetFlag) {
            if(gns.winSize !== 'm-l_size') {
              let hH = gns.header.outerHeight();
              targetOffset = targetOffset - hH;
            }
          }
          gns.htmlBody.velocity('scroll', {duration: 750, offset: targetOffset, easing: "easeOutQuart"});
          return false;
        }
      }
    });
    /* 別ページからのアンカーリンク設定（ヘッダーを考慮）
    -------------------------------------------- */
    gns.win.load(function () {
      //アンカーリンク取得
      let target = $(location.hash);
      //アンカーリンクがあった場合
      if(target.length > 0){
        let targetAncTop = target.offset().top;
        let targetOffset = gns.header.height();
        gns.win.scrollTop(0);
        setTimeout(function() {
          gns.win.scrollTop(0)
        }, 1);
        gns.win.delay(5).queue(function () {
          if (gns.anchorOffsetFlag) {
            targetAncTop -= targetOffset;
          }
          gns.win.scrollTop(targetAncTop);
        });
      }
    });
    /* ページの先頭へボタン表示・非表示
    -------------------------------------------- */
    gns.ptopNav = $('#ptop_nav');
    /*
    gns.ptopNav.dBottom = parseInt(gns.ptopNav.css('bottom'));
    */
    // gns.ptopNav.hide();
    gns.win.scroll(function() {
      let scVal = $(this).scrollTop();
      if (scVal > 200) {
        gns.ptopNav.addClass('show');
      } else {
        gns.ptopNav.removeClass('show');
      }
      // フッターと被るのを回避
      /*
      let ftRow02 = $('#footer #ft_row_02');
      let ftRow02Pos = ftRow02.position();
      let n = parseInt(scVal+gns.win.innerHeight()) - parseInt(ftRow02Pos.top);
      if(n > 0) {
        gns.ptopNav.css({'bottom':gns.ptopNav.dBottom + n + 'px'});
      } else {
        gns.ptopNav.css({'bottom':gns.ptopNav.dBottom + 'px'});
      }
      */
    });

    /* マウスのキーアップでフォーカスを解く
    -------------------------------------------- */
    gns.win.on('mouseup', function () {
      let win = $(this);
      win.find('a').blur();
      win.find('*[tabindex]').blur();
    });

    /* フォームのフィールドからフォーカスアウトしたら viewport を書き換える。（拡大を戻す。）
    -------------------------------------------- */
    $('input, select, textarea').on('blur', function(e){
      let metaViewportContent = gns.metaViewportContentTemp.replace(/minimum-scale/, "maximum-scale");
      gns.metaViewport.attr('content', metaViewportContent);
      let changeMetaViewport = setInterval(function(){
        if(getZoomePacentage() == 1) {
          gns.metaViewport.attr('content', gns.metaViewportContentTemp);
          clearTimeout(changeMetaViewport);
        }
      },100);
    });

    /* デバイスの縦横切り替えに応じて、viewport を書き換え。
    -------------------------------------------- */
    gns.metaViewport = $('meta[name="viewport"]');
    gns.metaViewportContentTemp = gns.metaViewport.attr('content');
    // デバイスの縦横で viewport を変更する
    gns.win.on(gns.orientationEvent, function() {
      // PC対応のみの場合
      if(gns.siteType == 'pc') return;
      // ↓ レスポンシブ
      gns.duringRotation = true;
      gns.metaViewport = $('meta[name="viewport"]');
      // デバイスの向きを取得
      let orient = getOrientation();
      // レスポンシブの場合
      if(gns.siteType == 'responsive') {
        setOrientCls(orient);
        gns.duringRotation = false;
      }
    });

    /* ピンチズーム時 クラス追加
    -------------------------------------------- */
    gns.win.on('load touchend', function(){
      let zoom = getZoomePacentage();
      if(zoom > 1) {
        gns.html.addClass('pinch_zoom');
        gns.pinchZoom = true;
      } else {
        gns.html.removeClass('pinch_zoom');
        gns.pinchZoom = false;
      }
    });
  });
})(jQuery);